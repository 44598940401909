import React from 'react';
import UI_NextProgect from './UI_NextProgect';
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";


import NextImg from "../matirials/Next-project/next-dashboard.png";
import NextLogo from "../matirials/Home/dashbord-logo-row.svg"

export default function RunGoNext(props) {
  return (
    <Fade delay={100}>
    <Link to="/dashboard" style={{textDecoration: "none"}}>
    <div className='next-container' id={props.id}> 
    <UI_NextProgect
        nextImg = {<img src={NextImg} className="next-img"></img>}
        nextLogo = {<img src={NextLogo} className="next-logo"></img>}
    />
    </div>
    </Link>
    </Fade>
  )
}
