import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home.js";
import Aime from "./Pages/Aime.js";
import Dashbord from "./Pages/Dashbord.js";
import Run from "./Pages/Run.js";
import Error from "./Pages/Error.js";
import ScrollToTop from "./ScrollToTop.js";


function App() {
  return (
    <>
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={ <Home />}> </Route>
          <Route path="/aime" element={  <Aime />}> </Route>
          <Route path="/dashboard" element={ <Dashbord />}> </Route>
          <Route path="/run" element={  <Run /> }> </Route>
          <Route path="*" element={  <Error /> }> </Route>
        </Routes>
      
      </BrowserRouter>
    </>
  );
}

export default App;
