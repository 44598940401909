import React from 'react';
import "../Styles/RunUI.scss";

import runUiHeader from "../matirials/Headers/header-ui.svg";

import Tilt from "react-parallax-tilt";
import { Fade } from "react-awesome-reveal";

export default function UIMobile (props) {
  return (
    <div className='run-ui-main-container' id={props.id}>
        <img src={runUiHeader} className='run-ui-main-header'></img>
        <div className='run-ui-img-flex'>
            <Tilt  tiltMaxAngleX="8" tiltMaxAngleY="16" scale={1.1} transitionSpeed={1500}>
                <Fade delay={400}>
                {props.img1}
                </Fade>
            </Tilt>

            <Tilt  tiltMaxAngleX="8" tiltMaxAngleY="16" scale={1.1} transitionSpeed={1500}>
                <Fade delay={200}>
                {props.img2}
                </Fade>
            </Tilt>

            <Tilt  tiltMaxAngleX="8" tiltMaxAngleY="16" scale={1.1} transitionSpeed={1500}>
                <Fade  delay={100}>
                {props.img3}
                </Fade>
            </Tilt>

            <Tilt  tiltMaxAngleX="8" tiltMaxAngleY="16" scale={1.1} transitionSpeed={1500}>
                <Fade delay={200}>
                {props.img4}
                </Fade>
            </Tilt>

            <Tilt  tiltMaxAngleX="8" tiltMaxAngleY="16" scale={1.1} transitionSpeed={1500}>
                <Fade delay={400}>
                {props.img5}
                </Fade>
            </Tilt>
        </div>
    </div>
  )
}
