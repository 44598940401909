import React from 'react';
import './UI_ProjectPageHero.scss';
import { Fade } from "react-awesome-reveal";


export default function UI_ProjectPageHero(props) {
  return (
    <div className='project-hero-grid-wrapper' id={props.id}>
      <div className='project-logo-flex'>
        <Fade>
        {props.logo}
        </Fade>
        {props.description}
      </div>
        {props.img}
    </div>
  )
}
