import React from 'react'
import UI_ChartItem from '../Components/UI_ChartItem';

import chartHeaeder from "../matirials/Headers/header-data.svg"


export default function AimeCharts(props) {
  return (
    <div className='aime-charts-sections-main-div' id={props.id}>
        <img className='charts-main-header' src={chartHeaeder}></img>
        <h1 className='aime-chart-description'>We examined <b>228</b> independent business owners in December 2022</h1>
    <div className='all-charts-container'>
        <div className='aime-charts-top-flex-with-q'> 
            <div className='q-wrap'>
                <h1>What can make you work with a plan? </h1>
                <h2>Marking more than one answer was possible</h2>
            </div>
        <div className="aime-charts-top-container">
        <UI_ChartItem 
            animation="animation1"
            color= "#007AFF"
            label="44.1"
            subLabel={<p><b>101</b>/228</p>}
            answer="If I will enjoy the plan and the work with it"
        />

        <UI_ChartItem 
            animation="animation2"
            color= "#007AFF"
            label="47.6"
            subLabel={<p><b>109</b>/228</p>}
            answer="If it helps me to be organized"
        />

        <UI_ChartItem 
            animation="animation3"
            color= "#007AFF"
            label="55.1"
            subLabel={<p><b>126</b>/228</p>}
            answer="If I get the results I want"
        />
        </div>
        </div>

        <div className='aime-charts-bottom-flex-with-q'> 
            <div className='q-wrap'>
                <h1>What can make you Abandon a plan? </h1>
                <h2>Marking more than one answer was possible</h2>
            </div>
        <div className="aime-charts-bottom-container">
        <UI_ChartItem 
            animation="animation4"
            color= "#F4511E"
            label="45.4"
            subLabel={<p><b>104</b>/228</p>}
            answer="If I feel that I have no freedom in the work plan"
        />

        <UI_ChartItem 
            animation="animation5"
            color= "#F4511E"
            label="52"
            subLabel={<p><b>119</b>/228</p>}
            answer="If I won't get results"
        />

        <UI_ChartItem 
            animation="animation6"
            color= "#F4511E"
            label="44.9"
            subLabel={<p><b>102</b>/228</p>}
            answer="If I won't understand what I need to do"
        />
        </div> 
        </div>
    </div>
    </div>
  )
}
