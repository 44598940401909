import React from 'react';
import "./UI_NextProgect.scss"

import NextCevron from "../matirials/Next-project/next-chevron.svg";

import Tilt from "react-parallax-tilt";


export default function UI_NextProgect(props) {
  return (

    <Tilt 
        tiltMaxAngleX="5" 
        tiltMaxAngleY="4"
        scale={1.04}
        transitionSpeed={1500} 
    >
        <div className='next-card'>

            <div className='next-chevron'>
                {<img src={NextCevron}></img>}
            </div>
            
            <div className='next-typo'>
                <h1>Thank you!</h1>
                <h2>Next Project</h2>
                {props.nextLogo}
            </div>

            {props.nextImg}
    </div>
    </Tilt>
  )
}
