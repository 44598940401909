import React from 'react';

import HomeHero from '../Components/HomeHero.js';
import HomeAbout from '../Components/HomeAbout.js';
import HomeProgects from '../Components/HomeProjects.js';
import HomeScroller from '../Scroller/HomeScroller.js';
import UI_Footer from "../Components/UI_Footer"


export default function Home() {
  return (
    <>
      <HomeScroller />
        <HomeHero id="HomeHero" />
        <HomeAbout id="HomeAbout" /> 
        <HomeProgects id="HomeProgects" />
        <UI_Footer id="HomeFooter"/>
    </>
  )
}
