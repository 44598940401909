import React from 'react';
import "./UI_UserIntervies.scss";
import { Fade } from "react-awesome-reveal"; 


export default function UI_UserIntervies(props) {
  return (
      <Fade>
        <div className='inteview-item'>
            {props.icon}
            {props.text}
        </div>
      </Fade>
  )
}



