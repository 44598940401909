// בשביל לשכפל לקומפוננטה בפרוייקט המקביל, בעתק הדבק ולששנות את הטקסטים

import React from 'react'
import UI_UserIntervies from './UI_UserIntervies';

import interviewMainHeader from "../matirials/Headers/header-intervies.svg"

import motivationIcon from "../matirials/Aime/check-mark.svg";
import frustrationIcon from "../matirials/Aime/x-mark.svg";

import qouteStart from "../matirials/Aime/qoute-start.svg";
import qouteEnd from "../matirials/Aime/qoute-end.svg";


export default function RunUserIntervies(props) {
  return (
    <div className='intervies-section-container' id={props.id}>
        <img src={interviewMainHeader} className='interview-main-header'></img>

        <div className='intervies-content-grid'>
        <div className='motivation-container'>

            <h1 className='motavationHeader'>Motivations</h1>

            <UI_UserIntervies 
                text= {<p>The app's accuracy is crucial.</p>}
                icon={<img src={motivationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I prefer running with a smartwatch and need a good connection between it and the app.</p>}
                icon={<img src={motivationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I want to view a summary of my run and its statistics.</p>}
                icon={<img src={motivationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>Running with others is enjoyable and motivating, and it's essential to feel a sense of togetherness.</p>}
                icon={<img src={motivationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I want to find the best running routes based on my goals.</p>}
                icon={<img src={motivationIcon} alt=""></img>}
            />

            <div className='motivation-qute-container'>
                <img src={qouteStart} className='qute-start-sign'></img>
                <p className='qute-text'>It's important for me to feel “togetherness”</p>
                <img src={qouteEnd} className='qute-end-sign'></img>
            </div>
        </div>

        <div className='frustration-container'>

            <h1 className='frustrationHeader'>Frustrations</h1>

            <UI_UserIntervies 
                text= {<p>I don't want to lose touch with group members who run at a slower or different pace.</p>}
                icon={<img src={frustrationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I don't want to have any communication difficulties<br></br> with others.</p>}
                icon={<img src={frustrationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I don't want to encounter issues receiving data <br></br>while running.</p>}
                icon={<img src={frustrationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I don't want too many features.</p>}
                icon={<img src={frustrationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I don't want to lose motivation during long runs.</p>}
                icon={<img src={frustrationIcon} alt=""></img>}
            />

            <div className='frustration-qute-container'>
                <img src={qouteStart} className='qute-start-sign'></img>
                <p className='qute-text'>I sometimes have negative thoughts while running, such as 'I'm tired' or 'I should stop now', and it's sucks</p>
                <img src={qouteEnd} className='qute-end-sign'></img>
            </div>

        </div>
        </div>
    </div>
  )
}

