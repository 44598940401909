import React from 'react';
import "../Styles/RunColors.scss";
import UI_ColorSwatch from './UI_ColorSwatch';

import aimeTolltip1 from "../matirials/Aime/aime-color-tool-tip-1.png";
import aimeTolltip2 from "../matirials/Aime/aime-color-tool-tip-2.png";
import aimeTolltip3 from "../matirials/Aime/aime-color-tool-tip-3.png";
import aimeTolltip4 from "../matirials/Aime/aime-color-tool-tip-4.png";
import aimeTolltip5 from "../matirials/Aime/aime-color-tool-tip-5.png";
import aimeTolltip6 from "../matirials/Aime/aime-color-tool-tip-6.png";


import RunColorMainHeader from "../matirials/Headers/header-colors.svg";

import hoverIcon from "../matirials/Aime/hover-icon.svg";


export default function AimeColors(props) {
  return (
    <div className='run-colors-container' id={props.id}>
        <img src={RunColorMainHeader} className="run-color-main-header"></img>
        
        <div className='hover-flex-container-in-run-colors'>
                  <img className="hover-icon-run-colors"src={hoverIcon}></img> 
                  <h1 className='hover-h1-run-colors'>hover swaches to reveal their secrets</h1>
        </div>
        
        <div className='run-swatches-container'>
        <UI_ColorSwatch 
            color="#FF6400"
            text={<p>Orange as the primary color. Orange is associated with energy, enthusiasm, and optimism. I belive it's a good choice for an app that encourages users to stay motivated with their tasks.</p>}
            img={<img src={aimeTolltip1} alt="" className='run-colors-tooltip-img'></img>}
        />

        <UI_ColorSwatch 
            color="#FDE8D8"
            text={<p>I took yhe primary color, and lower it's opacity to 15%. This was made to create hierarchy.</p>}
            img={<img src={aimeTolltip2} alt="" className='run-colors-tooltip-img'></img>}
        />

        <UI_ColorSwatch 
            color="#BFE57E"
            text={<p>“aiMe’s” text bubbles have a light green shade that complements the primary color, Thw Goal was to create harmonic color scheme, but also let aiMe's text bubbles pop.</p>}
            img={<img src={aimeTolltip3} alt="" className='run-colors-tooltip-img'></img>}
        />

        <UI_ColorSwatch 
            color="#263238"
            text={<p>Primay neutral grey shade for the main headers.</p>}
            img={<img src={aimeTolltip4} alt="" className='run-colors-tooltip-img'></img>}
        />

        <UI_ColorSwatch 
            color="#455A64"
            text={<p>Secondary neutral grey shade for the rest of the typography halps me to create hierarchy. </p>}
            img={<img src={aimeTolltip5} alt="" className='run-colors-tooltip-img'></img>}
        />

        <UI_ColorSwatch 
            color="#F5F6FA"
            text={<p>Ultra light grey color, to create hierarchy in the background. This slightly blue shade noticeable over the white sections. </p>}
            img={<img src={aimeTolltip6} alt="" className='run-colors-tooltip-img'></img>}
        />
    </div>

    </div>
  )
}
