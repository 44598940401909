import React from 'react';
import UI_SingleNoteAime from "./UI_SingleNote-Aime";

import notesHeader from "../matirials/Headers/header-canvas.svg";
import hoverIcon from "../matirials/Aime/hover-icon.svg";

export default function UI_NoteLayerAime(props) {
  return (
    <div className="note-section" id={props.id}>
      <img src={notesHeader} className="notes-header"></img>

      <div className='hover-notes-order'>
        <img src={hoverIcon}></img>
        <h1>Hover Category to view</h1>
      </div>

    <div className='notes-flex-container'>
      <UI_SingleNoteAime/>
    </div>

    </div>
  )
}
