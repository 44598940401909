import React from 'react';
import "./UI_SolutionValues.scss";

export default function UI_SolutionValues(props) {
  return (
    
      <div className='dash-values-flex'>
          <h1 className='dash-val-1'>{props.val1}</h1>
          <h1 className='dash-val-2'>{props.val2}</h1>
          <h1 className='dash-val-3'>{props.val3}</h1>
      </div>
  )
}
