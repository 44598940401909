import React from 'react';
import ScrollItem from "./ScrollItem";

export default function AimeScroller() {
  return (
    <nav>
    <ul>
        <ScrollItem id="aimeHero" label="Project"/>
        <ScrollItem id="aimeVision" label="Vision"/>
        <ScrollItem id="AimeUserIntervies" label="User Interviews"/>
        <ScrollItem id="AimePersonas" label="Personas"/>
        <ScrollItem id="AimeCharts" label="Quantitative Data"/>
        <ScrollItem id="AimeNotes" label="Lean UX Canvas"/>
        <ScrollItem id="AimeSolution" label="Solution"/>
        <ScrollItem id="AimeFlow" label="Flow"/>
        <ScrollItem id="aimeNav" label="Navigation"/>
        <ScrollItem id="aimeCopm" label="Components"/>
        <ScrollItem id="AimeColors" label="Colors"/>
        <ScrollItem id="AimeUIMobile" label="UI"/>
        <ScrollItem id="AimeGoNext" label="Next Project"/>
    </ul>
</nav>
  )
}
