import React from 'react';
import "../Styles/AimeNav.scss";

import AimeNavHeader from "../matirials/Headers/header-nav.svg";
import AimeNavImg from "../matirials/Aime/aime-nav.png"

export default function AimeNav(props) {
  return (
    <div id={props.id} className="aime-nav-container">
        <img src={AimeNavHeader} className="aime-nav-header"></img>

        <img src={AimeNavImg} className="aime-nav-Img"></img>

        <p>To select the primary navigation categories in the app, the guiding principle was to consider why users would visit the application and what they would be searching for. I belive it is crucial to make deliberate choices in the navigation as it communicates the content of the application to the user.</p>

     </div>
  )
}
