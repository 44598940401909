import React from 'react';
import ScrollItem from "./ScrollItem";


export default function HomeScroller() {
  return (
    <nav>
    <ul>
        <ScrollItem id="HomeHero" label="Home"/>
        <ScrollItem id="HomeAbout" label="About Me"/>
        <ScrollItem id="HomeProgects" label="Projects"/>
        <ScrollItem id="HomeFooter" label="Contact"/>
    </ul>
</nav>
  )
}
