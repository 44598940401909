import { useState, useEffect, useRef } from 'react';
import "./UI_ChartItem.scss";

import AimeIntarval from "./AimeIntarval.js";


export default function UI_ChartItem(props) {

    // start of visibility triger, ref is the element we want to use the triger on. 
    const myRef = useRef();
    const [visible, setVisible] = useState();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setVisible(entry.isIntersecting);
        })
        observer.observe(myRef.current);
    },[])
    // end of visibility triger

    const dashColor = {
        stroke: props.color
    }

  return (
    <div className='circle-rotate'>
        <div className="chart-item-text">
            <p className="chart-main-p" >{visible ? <AimeIntarval label={props.label}/> : 0}</p>
            <p className="chart-sub-p">{props.subLabel}</p>
            <p className="chart-answer">{props.answer}</p>
        </div>

        <svg className='chart'>

        <circle 
                cx="70" 
                cy="70" 
                r="60" 
                className="bg-circle"
            ></circle>

            <circle  ref={myRef}
                cx="70" 
                cy="70" 
                r="60" 

                style={dashColor}
                className={`${visible ? (`chart-circle ${props.animation}`) : 'chart-circle'}`}
            ></circle>

        </svg>
    </div>
  )
}
