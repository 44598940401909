import React from 'react';
import UI_ProjectPageHero from '../Components/UI_ProjectPageHero';

import dashbordLogoHero from "../matirials/Dashboard/dashboard-hero-logo.svg";
import dashbordImgHero from "../matirials/Dashboard/dashboard-hero-img.png";
import UI_ProjectVision from '../Components/UI_ProjectVision';
import DashboardData from '../Components/DashboardData.js';
import DashbordSolution from '../Components/DashbordSolution.js';
import DashbordWireFrames from '../Components/DashbordWireFrames';
import DashboardColors from '../Components/DashboardColors';
import DashboardTypography from '../Components/DashboardTypography';
import DashboardUI from '../Components/DashboardUI';
import DashboardGoNext from '../Components/DashboardGoNext';
import DashboardScroller from '../Scroller/DashboardScroller';
import UI_BackHomePage from "../Components/UI_BackHomePage.js"
import UI_Footer from "../Components/UI_Footer"


export default function Dashbord() {
  return (
    <>

    <DashboardScroller /> 

    <UI_BackHomePage />

    <UI_ProjectPageHero id="DashbordProjectHero"
      logo = {<img src={dashbordLogoHero} alt=""></img>}
      description = {<h1>UX | UI | Dashboard</h1>}
      img = {<img className='hero-img-project' src={dashbordImgHero} alt=""></img>}    
    />

    <UI_ProjectVision id="DashbordProjectVision"
      visionText = {<h1>The goal is to assist small store owners in managing their inventory, cash register, and employees by providing clarity and a single platform to manage all of these aspects.</h1>}
    /> 

    <DashboardData id="DashboardData"/>

    <DashbordSolution id="DashbordSolution"/>

    <DashbordWireFrames id="DashbordWireFrames"/>

    <DashboardColors id="DashboardColors"/>

    <DashboardTypography id="DashboardTypography"/>

    <DashboardUI id="DashboardUI"/>

    <DashboardGoNext id="DashboardGoNext"/>

    <UI_Footer />
    </>


  )
}
