import React from 'react';
import "../Styles/RunFlow.scss"
import runFlowHeader from "../matirials/Headers/header-flow.svg";
import runFlow from "../matirials/Run/run-flow.png"

export default function RunFlow(props) {
  return (
    <div className='run-flow-container' id={props.id}>
        <img src={runFlowHeader} className='run-flow-main-header'></img>
        <div>
        <img src={runFlow} className='run-flow'></img>
        </div>
    </div>
  )
}
