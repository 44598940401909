import React, { useState, useEffect } from "react";
import "../Styles/HomeHeroTypo.scss";
import {Fade}  from "react-awesome-reveal";

export default function HomeHeroTypo() {

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth <= 488);
    }

    window.addEventListener("resize", handleResize);

    // cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
        <div className='hero-typo-container'>
      <Fade direction="up">
          <h1 className='hero-typo-container-h1'>Hi, I’m Roni 👋</h1>
          <h2 className='hero-typo-container-h2'>UX <span>|</span> UI {isSmallScreen ? "" : <span>|</span>} Developer</h2>
        </Fade>
        </div> 
    </>
  )
}
