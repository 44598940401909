import React from 'react';
import "../Styles/RunTypo.scss";

import runTypo from "../matirials/Run/run-typo.png";
import typoRinMainHeader from "../matirials/Headers/header-typo.svg"

export default function RunTypo(props) {
  return (
    <div className='run-typo-container' id={props.id}>
        <img className='run-typo-main-header' src={typoRinMainHeader}></img>
        <div className='run-typo-grid'>
            <p className='run-typo-p'> <b>I picked a rounded font to create a soft and approachable feeling and to match the social atmosphere. </b>I also kept the font simple, without any contrast to avoid a sophisticated feel.<b> I was inspired by the rounded font and use’d it for the design of the buttons and cards,</b> which also have rounded features.</p>
            <img className="run-typo-img" src={runTypo} alt=""></img>
        </div>
    </div>
  )
}
