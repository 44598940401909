import { useState } from 'react';
import "../Styles/AimePersonas.scss"

import aimePersonasHeader from "../matirials/Headers/header-personas.svg";

import persona1 from "../matirials/Aime/persona-1.png";
import persona2 from "../matirials/Aime/persona-2.png";

import hoverIcon from "../matirials/Aime/hover-icon.svg";

export default function AimePersonas(props) {

    const [scale1, setScale1] = useState(false);
    const [scale2, setScale2] = useState(false);


    const peraonaScale1 = (e) => { 
        if (e.type === "mouseenter") {
            setScale1 (true);
        }
    }

    const removePeraonaScale1 = (e) => { 
        if (e.type === "mouseleave") {
            setScale1 (false);
        }
    }

    const peraonaScale2 = (e) => { 
        if (e.type === "mouseenter") {
            setScale2 (true);
        }
    }

    const removePeraonaScale2 = (e) => { 
        if (e.type === "mouseleave") {
            setScale2 (false);
        }
    }



  return (
    <div className='aime-personas-main-container' id={props.id}>
        <img src={aimePersonasHeader} className='aime-personas-header'></img>

        <div className='hover-flex-container-in-personas'>
                  <img className="hover-icon-personas"src={hoverIcon}></img> 
                  <h1 className='hover-h1-personas'>hover to increase view</h1>
        </div>
        

        <div className='personas-flex'>
            <img 
                src={persona1} 
                className={scale1 ? 'persona1-scale' : 'persona1'}
                onMouseEnter={peraonaScale1}
                onMouseLeave={removePeraonaScale1}
            ></img>
            <img 
                src={persona2} 
                className={scale2 ? 'persona2-scale' : 'persona2'}
                onMouseEnter={peraonaScale2}
                onMouseLeave={removePeraonaScale2}
            ></img>
        </div>
    </div>
  )
}
