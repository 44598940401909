import React from 'react';
import "./UI_ProjectCard.scss";

import Tilt from 'react-parallax-tilt';
import {Fade}  from "react-awesome-reveal";


export default function ProjectsCard(props) {
  return (
    <Fade delay={100} >
    <Tilt 
      tiltMaxAngleX="5" 
      tiltMaxAngleY="4"
      scale={1.04}
      transitionSpeed={1500}
      >
    <div 
      className='projects-card inner-element'>
        <div className='projects-card-flex-wrap'>
          {props.meta}
          {props.appLogo}
          {props.text}
          {props.btn}
        </div>
          {props.img}
      </div>
      </Tilt>
      </Fade>
  )
}
