import React from 'react';
import "../Styles/HomeAbout.scss";
import aboutHeader from "../matirials/Headers/header-about-me.svg";
import myImg from "../matirials/Home/my-img.png";


export default function HomeAbout(props) {
  return (
    <div className='about-container' id={props.id}>
        <img className='about-header' src={ aboutHeader } alt=""></img>

        <div className='about-content-flex'>
            <div className='about-text'> 
                <h1 className='about-text-h1'>Hello World!</h1>
                <p className='about-text-p'>
                Hi, my name is Roni Mor and I'm passionate about what I do. I studied <b className='b'>UX/UI</b> at Shenkar 
                and <b className='b'>Fullstack Development</b> at Netcraf. I enjoy creating <b className='b'>unique experiences</b> with 
                <b className='b'> attention to detail,</b> and my ability to develop allows me to bring my ideas to life.
                You're invited to explore my work, where I combine <b className='b'>love, creativity, and a little bit of coffee...</b>
                </p>
            </div>

            <img className='about-img' src={myImg} alt=""></img>
        </div>
    </div>
  )
}
