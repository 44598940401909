import React from 'react';
import "../Styles/RunPhoto.scss";

import runImg1 from "../matirials/Run/run-img-1.png";
import runImg2 from "../matirials/Run/run-img-2.png";
import runImg3 from "../matirials/Run/run-img-3.png";
import runImg4 from "../matirials/Run/run-img-4.png";

import runImgMainHeager from "../matirials/Headers/header-photo.svg"


export default function RunPhoto(props) {
  return (
    <div className='run-photo-container' id={props.id}>
        <img className='run-img-main-haeder' src={runImgMainHeager}></img>

        <div className='run-img-and-p-container'>
        <div className='run-photos-flex'>
            <img src={runImg1} className='run-img-item'></img>
            <img src={runImg2} className='run-img-item'></img>
            <img src={runImg3} className='run-img-item'></img>
            <img src={runImg4} className='run-img-item'></img>
        </div>

        <p className='run-img-p'>I used images of people enjoying themselves while engaging in activities, with a variety of population represented to create an <b>inclusive and inviting atmosphere that appeals to everyone, in order to complete the social experience.</b></p>
        </div>
    </div>
  )
}
