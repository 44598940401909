import React from 'react';
import "../Styles/HomeProgects.scss";
import { Link } from 'react-router-dom';

import ProjectsCard from "./UI_ProjectsCard.js";

import mainHeader from "../matirials/Headers/header-projects.svg";

import aimeLogo from "../matirials/Home/aime-logo-row.svg";
import dashboardLogo from "../matirials/Home/dashbord-logo-row.svg";
import runLogo from "../matirials/Home/run-logo-row.svg";

import aimeImg from "../matirials/Home/aime-mockup.png";
import dashboardImg from "../matirials/Home/dashbord-mockup.png"; 
import runImg from "../matirials/Home/run-mockup.png";

import btnChevron from "../matirials/Home/btn-chevron.svg";



export default function (props) {
  return (
    <>
        <div className='home-projects-container' id={props.id}>

            <img src={mainHeader} alt="" className='projects-main-header'></img>

              <div className='cards-flex-wrapper'>

                <ProjectsCard
                    meta= {
                      <h1 
                        className='proj-meta'>
                          UX | UI | Mobile App
                      </h1>}

                    appLogo= {
                      <img src={aimeLogo} alt="" 
                        className='proj-logo'>
                      </img>}

                    text= {
                      <p
                        className='proj-text'>
                        This app helps <b className='proj-text-bold-text'>small business owners</b> achieve their goals through <b className='proj-text-bold-text'>fun and interactive work planning.</b>
                      </p>
                    }
                    btn= {
                      <Link to="/aime" style={{textDecoration: "none"}}>
                      <button
                        className='proj-btn'>
                        view project {<img src={btnChevron} alt=""></img>}
                      </button>
                      </Link>
                    }
                    img= {
                      <img src={aimeImg} alt="" 
                        className='proj-img'>
                      </img>}
                />

                <ProjectsCard
                    meta= {
                      <h1 
                        className='proj-meta'>
                          UX | UI | dashboard
                      </h1>}

                    appLogo= {
                      <img src={dashboardLogo} alt="" 
                        className='proj-logo'>
                      </img>}

                    text= {
                      <p
                        className='proj-text'>
                        This app helps <b className='proj-text-bold-text'>small store owners</b> manage their <b className='proj-text-bold-text'>inventory, cash register, and employees </b>by providing a single platform to <b className='proj-text-bold-text'>handle everything in one place.</b>
                      </p>
                    }
                    btn= {
                      <Link to="/dashboard" style={{textDecoration: "none"}}>
                      <button
                        className='proj-btn'>
                        view project {<img src={btnChevron} alt=""></img>}
                      </button>
                      </Link>
                    }
                    img= {
                      <img src={dashboardImg} alt="" 
                        className='proj-img'>
                      </img>}
                />

                <ProjectsCard
                    meta= {
                      <h1 
                        className='proj-meta'>
                          UX | UI | Mobile App
                      </h1>}

                    appLogo= {
                      <img src={runLogo} alt="" 
                        className='proj-logo'>
                      </img>}

                    text= {
                      <p
                        className='proj-text'>
                        This app enhances the <b className='proj-text-bold-text'>running experience of groups </b>by providing a <b className='proj-text-bold-text'>single platform </b>to manage both the group and running activities. 
                      </p>
                    }
                    btn= {
                      <Link to="/run" style={{textDecoration: "none"}}>
                      <button
                        className='proj-btn'>
                        view project {<img src={btnChevron} alt=""></img>}
                      </button>
                      </Link>
                    }
                    img= {
                      <img src={runImg} alt="" 
                        className='proj-img'>
                      </img>}
                />


            </div>
        </div>
    </>
  )
}
