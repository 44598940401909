import React from 'react';
import "../Styles/RunColors.scss";
import UI_ColorSwatch from './UI_ColorSwatch';

import runTolltip1 from "../matirials/Run/run-color-tooltip-1.png";
import runTolltip2 from "../matirials/Run/run-color-tooltip-2.png";
import runTolltip3 from "../matirials/Run/run-color-tooltip-3.png";
import runTolltip4 from "../matirials/Run/run-color-tooltip-4.png";
import runTolltip5 from "../matirials/Run/run-color-tooltip-5.png";

import RunColorMainHeader from "../matirials/Headers/header-colors.svg";

import hoverIcon from "../matirials/Aime/hover-icon.svg";


export default function RunColors(props) {
  return (
    <div className='run-colors-container' id={props.id}>
        <img src={RunColorMainHeader} className="run-color-main-header"></img>
        
        <div className='hover-flex-container-in-run-colors'>
                  <img className="hover-icon-run-colors"src={hoverIcon}></img> 
                  <h1 className='hover-h1-run-colors'>hover swaches to reveal their secrets</h1>
        </div>
        
        <div className='run-swatches-container'>
        <UI_ColorSwatch 
            color="#C6FF00"
            text={<p>I chose a primary color that's between green and yellow. Yellow represents joy and energy, while green represents stability and health.</p>}
            img={<img src={runTolltip1} alt="" className='run-colors-tooltip-img'></img>}
        />

        <UI_ColorSwatch 
            color="#A3BCCC"
            text={<p>The secondary colors provide contrast in saturation, hue (locate 120 degrees from the primary color), and brightness, to support the main color.</p>}
            img={<img src={runTolltip2} alt="" className='run-colors-tooltip-img'></img>}
        />

        <UI_ColorSwatch 
            color="#597180"
            text={<p>The secondary colors provide contrast in saturation, hue (locate 120 degrees from the primary color), and brightness, to support the main color.</p>}
            img={<img src={runTolltip3} alt="" className='run-colors-tooltip-img'></img>}
        />

        <UI_ColorSwatch 
            color="#4C5E66"
            text={<p>The secondary colors provide contrast in saturation, hue (locate 120 degrees from the primary color), and brightness, to support the main color.</p>}
            img={<img src={runTolltip4} alt="" className='run-colors-tooltip-img'></img>}
        />

        <UI_ColorSwatch 
            color="#333D40"
            text={<p>The secondary colors provide contrast in saturation, hue (locate 120 degrees from the primary color), and brightness, to support the main color.</p>}
            img={<img src={runTolltip5} alt="" className='run-colors-tooltip-img'></img>}
        />
    </div>

    </div>
  )
}
