import { useState, useEffect  } from 'react';

export default function AimeIntarval(props) {

    let [amount, setAmount] = useState(0);
    let progressEndValue  = props.label;
    let speed = 1.5; 
    
   useEffect (() => {
        const percentageProgress = setInterval(() => {
            if (amount < progressEndValue) {
                setAmount(prev => prev + 0.1);
            } else {
                clearInterval(percentageProgress);  
            }
        },speed);
           return () => clearInterval(percentageProgress);
        
    },[amount]);


  return (
    <div>{`${amount.toFixed(1)}%`}</div>
  )
}
