import React, { useState } from 'react';
import "./UI_Gallery.scss";

import ui1 from "../matirials/Dashboard/dashboard-1.png";
import ui2 from "../matirials/Dashboard/dashboard-2.png";
import ui3 from "../matirials/Dashboard/dashboard-3.png";
import ui4 from "../matirials/Dashboard/dashboard-4.png";

import rightChevron from "../matirials/Dashboard/chevron-right.svg";
import leftChevron from "../matirials/Dashboard/chevron-left.svg";



export default function DashWIreGallery() {

    const [scale, setScale] = useState(1.155);

    const classArray = ['item1', 'item2', 'item3', 'item4'];
    const [currentArray, setArray] =  useState(classArray);

        const indicatorsClasses = ['choose', 'not', 'not', 'not'];
        const [indicatorsArray, setIndocator] =  useState(indicatorsClasses);

        const rightIndicatorMove = (arr) => {
            const position = arr[0];
            arr.shift();
            arr.push(position);
     
            return arr;
        }

        const leftIndicatorMove = (arr) => {
            const position = arr[3];
            arr.pop();
            arr.unshift(position);
     
            return arr;
        }

        //left side start
        const leftArryUpdate = (arr) => {
            const position = arr[0];
            arr.shift();
            arr.push(position);

            return arr; 
        }

        const leftClickHandler = () => {
            const sprededArr = [...currentArray]
            setArray(leftArryUpdate(sprededArr));
            console.log(sprededArr);

            const spredIndicator = [...indicatorsArray]
            setIndocator(rightIndicatorMove(spredIndicator));
        }
        //left side end

        //right side start
        const rightArryUpdate = (arr) => {
            const position = arr[3];
            arr.pop();
            arr.unshift(position);

            return arr; 
        }

        const rightClickHandler = () => {
            const sprededArr = [...currentArray]
            setArray(rightArryUpdate(sprededArr));
            console.log(sprededArr);
          
            const spredIndicator = [...indicatorsArray]
            setIndocator(leftIndicatorMove(spredIndicator));

        }
        //right side end


  return (

    <>
    <div className='flex-body'>

        <div className='gallery'> 

            <div className='gallery-container'>
                <img src={ui1} className={`item ${currentArray[0]}`} alt=""></img>
                <img src={ui2} className={`item ${currentArray[1]}`} alt=""></img>
                <img src={ui3} className={`item ${currentArray[2]}`} alt=""></img>
                <img src={ui4} className={`item ${currentArray[3]}`} alt=""></img>
            </div>

        </div>
                <div className='gallery-indicators'>

                <button onClick={leftClickHandler} className='btn btn-right'>{<img src={leftChevron} alt=""></img>}</button>

                    <div className= {`indicator ${indicatorsArray[0]}`}></div>
                    <div className={`indicator ${indicatorsArray[1]}`}></div>
                    <div className={`indicator ${indicatorsArray[2]}`}></div>
                    <div className={`indicator ${indicatorsArray[3]}`}></div>
                    
                <button onClick={rightClickHandler} className='btn btn-left'>{<img src={rightChevron} alt=""></img>}</button>
                </div>
    </div>

    </>
  )
}
