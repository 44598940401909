import React from 'react';
import "../Styles/RunSolution.scss";

import runSolutionMainHeader from "../matirials/Headers/header-solusion.svg"
import UI_SolutionValues from './UI_SolutionValues';

import runSolutionNotebook from "../matirials/Run/run-notebook.png"


export default function RunSolution(props) {
  return (
    <div className='run-solution-main-container' id={props.id}>
        <img src={runSolutionMainHeader} className="run-solution-main-header"></img>

        <div className='run-solution-contant-grid'>
            <div className='run-solition-cintant-flex'>
                <p>I wanted to make an app that <b>focuses on the social aspect </b>of running in groups, while also giving runners <b>precise information and stats.</b> I focused on group running as the primary scenario for the solution,<b> and used the following values to guide me throughout the process:</b></p>
                <UI_SolutionValues 
                    val1="Sociable"
                    val2="Energetic"
                    val3="Accurate"
                />
            </div>
            <img className='run-solution-notebook' src={runSolutionNotebook}></img>
        </div>
    </div>
  )
}
