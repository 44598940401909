import React from 'react';
import "../Styles/DashbordWireFrames.scss";

import headerUIDash from "../matirials/Headers/header-ui.svg";
import DashUIGallery from './DashUIGallery';

import hoverIcon from "../matirials/Aime/hover-icon.svg"


export default function DashbordWireFrames(props) {
  return (
    <div className='dash-wire-container ui-gallery-container' id={props.id}>
        <img src={headerUIDash} alt="" className='dash-wire-header'></img>

        <div className='wireframes-dash-flex-container ui-flex-gellary-container'>

            <div className='gallery-and-hover-h1'>

                <div className='hover-flex-container ui-hover-flex-container'>
                  <img className="hover-icon"src={hoverIcon}></img> 
                  <h1 className='hover-h1'>hover for some fun</h1>
                </div>

                <DashUIGallery />
            </div>

        </div>

    </div>
  )
}