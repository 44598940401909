import React from 'react';
import "../Styles/DashbordSolution.scss";

import headerSolutionDash from "../matirials/Headers/header-solusion.svg";
import dashSolutionImg from "../matirials/Dashboard/lf-wiredrames-dashboard(1).png"

import UI_SolutionValues from './UI_SolutionValues';


export default function DashbordSolution(props) {
  return (
    <>
        <div className='dash-solution-container' id={props.id} >
            <img src={headerSolutionDash} alt="" className='dash-solution-header'></img>
            
            <div className='dash-solution-container-grid'>

                <div className='dash-solution-cont-flex'>
                    <p classList="dash-solution-paragraph">
                            In a store i used to work, <b className='dash-solution-b'>I saw 
                            the store manager rushing around doing inventory and orders, </b>
                            so I wanted to create a system that could be accessed on a tablet. 
                            This way, the manager could <b className='dash-solution-b'>move around the store and manage orders, 
                            while also easily getting an overview of what she needed.
                            I used the following values to guide me throughout the process:</b>
                    </p>

                    <UI_SolutionValues 
                        val1="Reliable"
                        val2="Professional"
                        val3="Stability"
                    />

                </div>

                    <img src={dashSolutionImg} alt="" className='dash-solution-img'></img>

            </div>
        </div>
    </>
  )
}
