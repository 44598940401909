import React from 'react';
import HomeHeroSymbols from '../Components/HomeHeroSymbols.js';
import HomeHeroBG from './HomeHeroBG.js';
import HomeHeroTypo from './HomeHeroTypo.js';

import  scrollChevron from "../matirials/Home/scroll-icon.svg";

import "../Styles/HomeHero.scss"


export default function HomeHero(props) {
  return (
    <div id={props.id}>
      <HomeHeroTypo />
      <HomeHeroSymbols />
      <HomeHeroBG />

      <div className='home-scroll'>
            <img src = {scrollChevron} alt=""></img>
            <h1 className='home-scroll-h1'>SCROLL TO START THE EXPERIENCE</h1>
      </div>
    </div>
  )
}
