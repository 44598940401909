import React from 'react';
import "../Styles/AimeComp.scss";
 
import aimeCompHeader from "../matirials/Headers/hedaer-comp.svg";
import aimeCompImg from "../matirials/Aime/aime-comp.png"

export default function AimeCopm(props) {
  return (
    <div id={props.id} className="aime-comp-container">
        <img src={aimeCompHeader} className="aime-comp-main-header"></img>

        <div className='aime-comp-flex'>
            <div className='aime-cont-comp-text'>
                <h1>As Simple as Use a Pen</h1>
                <p>The interface components were designed with simplicity in mind, which aligns with the product's values. The idea is to give the feeling that managing a business in a smart and good way is something that anyone can do.</p>
            </div>
            <img src={aimeCompImg} className="aime-comp-img"></img>
        </div>
    </div>
  )
}
