import React from 'react';
import "./UI_ProjectVision.scss";

import visionMainHeader from "../matirials/Headers/header-vision.svg"

export default function UI_ProjectVision(props) {
  return (
          <div className='vision-container' id={props.id}>
              <img src={visionMainHeader} alt="" className='vision-main-haeder'></img>
                {props.visionText}
          </div>
  )
}
