import React from 'react';
import { Link } from "react-router-dom"
import UI_NextProgect from './UI_NextProgect';
import { Fade } from "react-awesome-reveal";

import NextImg from "../matirials/Next-project/next-run.png";
import NextLogo from "../matirials/Home/run-logo-row.svg"

export default function DashboardGoNext(props) {
  return (
    <Link to="/run" style={{textDecoration: "none"}}>
    <div className='next-container grey' id={props.id}> 
      <Fade delay={100}>
        <UI_NextProgect 
            nextImg = {<img src={NextImg} className="next-img"></img>}
            nextLogo = {<img src={NextLogo} className="next-logo"></img>}
        />
    </Fade>
    </div>
    </Link>
  )
}
