import React from 'react';
import { Link } from "react-scroll";

import "./Scroller.scss";

export default function ScrollItem(props) {
  return (
    <li className="nav-item">
    <Link 
        className='circle' 
        to={props.id}
        spy={true}
        smooth={true}
        duration={500}
        activeClass="active-circle"
        ></Link>
    
    <Link 
        className="a" 
        to={props.id}
        spy={true}
        smooth={true}
        duration={500}
    >{props.label}</Link>

</li>
  )
}
