import React from 'react';
import '../Styles/HomeHeroSymbols.scss'; 

import symbolCssShort from '../matirials/Home/symbol-css-short.svg';
import symbolPentoolShort from '../matirials/Home/symbol-pentool-short.svg';
import symbolReactShort from '../matirials/Home/symbol-react-short.svg';
import symbolJsShort from '../matirials/Home/symbol-js-short.svg';

import symbolTypoMeduim from '../matirials/Home/symbol-typograohy-medium.svg';
import symbolEyeMeduim from '../matirials/Home/symbol-eyedroperr-medium.svg';
import symbolVertMeduim from '../matirials/Home/symbol-vertecis-medium.svg';
import symbolGitMeduim from '../matirials/Home/symbol-git-medium.svg';

import symbolTypoLong from '../matirials/Home/symbol-typograohy-long.svg';
import symbolVertLong from '../matirials/Home/symbol-vertecis-long.svg';
import symbolJsLong from '../matirials/Home/symbol-js-long.svg';
import symbolHtmlLong from '../matirials/Home/symbol-html-long.svg';

import { MouseParallax } from "react-just-parallax";


export default function HomeHeroSymbols() {
  return (
    <div className='parallax-container'>
            <MouseParallax isAbsolutelyPositioned="true" zIndex="1" strength="0.08" >
                <img src={symbolCssShort} alt="" className="symbols-short-css"></img>
                <img src={symbolPentoolShort} alt="" className="symbols-short-pen"></img>
                <img src={symbolReactShort} alt="" className="symbols-short-react"></img>
                <img src={symbolJsShort} alt="" className="symbols-short-js"></img>
             </MouseParallax>

             <MouseParallax isAbsolutelyPositioned="true" zIndex="1" strength="0.06" >
                <img src={symbolTypoMeduim} alt="" className="symbols-medium-typo"></img>
                <img src={symbolEyeMeduim} alt="" className="symbols-medium-eye"></img>
                <img src={symbolVertMeduim} alt="" className="symbols-medium-vert"></img>
                <img src={symbolGitMeduim} alt="" className="symbols-medium-git"></img>
              </MouseParallax>

              <MouseParallax isAbsolutelyPositioned="true" zIndex="1" strength="0.03" >
                <img src={symbolTypoLong} alt="" className="symbols-long-typo"></img>
                <img src={symbolVertLong} alt="" className="symbols-long-vert"></img>
                <img src={symbolJsLong} alt="" className="symbols-long-js"></img>
                <img src={symbolHtmlLong} alt="" className="symbols-long-html"></img>
              </MouseParallax>
      
</div>
  )
}
