import { useState } from 'react';
import "./UI_NoteClose.scss";

import note1 from "../matirials/notes/note-group-1.svg";
import note2 from "../matirials/notes/note-group-2.svg";
import note3 from "../matirials/notes/note-group-3.svg";
import note4 from "../matirials/notes/note-group-4.svg";
import note5 from "../matirials/notes/note-group-5.svg";
import note6 from "../matirials/notes/note-group-6.svg";
import note7 from "../matirials/notes/note-group-7.svg";
import note8 from "../matirials/notes/note-group-8.svg";
import note9 from "../matirials/notes/note-group-9.svg";

//open notes => replaceable
import openNote1 from "../matirials/notes/Run-notes/run-note-group-1.svg";
import openNote2 from "../matirials/notes/Run-notes/run-note-group-2.svg";
import openNote3 from "../matirials/notes/Run-notes/run-note-group-3.svg";
import openNote4 from "../matirials/notes/Run-notes/run-note-group-4.svg";
import openNote5 from "../matirials/notes/Run-notes/run-note-group-5.svg";
import openNote6 from "../matirials/notes/Run-notes/run-note-group-6.svg";
import openNote7 from "../matirials/notes/Run-notes/run-note-group-7.svg";
import openNote8 from "../matirials/notes/Run-notes/run-note-group-8.svg";
import openNote9 from "../matirials/notes/Run-notes/run-note-group-9.svg";


export default function UI_SingleNoteRun() {
  
const noteClasses = ['note-close', 'note-close', 'note-close', 'note-close', 'note-close', 'note-close', 'note-close', 'note-close', 'note-close'];
const [NoteclassArr, setClassArr] = useState(noteClasses);
  
const mouseEnterHandler = (e)=> {
    const spreadNoteArr = [...NoteclassArr];
    const classIndex = Number(e.target.id);
    console.log(classIndex);
    console.log(e.target.id);
    for (let i=0; i < 10; i++) {
      spreadNoteArr.shift([i]);
      spreadNoteArr.push('note-close blur');
    }
    spreadNoteArr.splice(classIndex, 1, 'note-close select');
    setClassArr(spreadNoteArr);
    console.log(spreadNoteArr);
}


const mouseLeaveHandler = () => {
    const spreadNoteArr = [...NoteclassArr];
    for (let i=0; i < 10; i++) {
        spreadNoteArr.shift([i]);
        spreadNoteArr.push('note-close');
    }
    setClassArr(spreadNoteArr);
    console.log(spreadNoteArr);
}
    return (
        <>
        <span className='close-note-1'>
        <img
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            src={note1} 
            className={NoteclassArr[0]}
            id='0'  
        >
            
        </img>
        <img src={openNote1} className="note-open open-note-1"></img>
        </span>

        <span className='close-note-2'>
        <img
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            src={note2} 
            className={NoteclassArr[1]}
            id='1'  
          ></img>
        <img src={openNote2} className="note-open open-note-2"></img>
        </span>

        <span className='close-note-3'>
        <img
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            src={note3} 
            className={NoteclassArr[2]}
            id='2'  
          ></img>
        <img src={openNote3} className="note-open open-note-3"></img>
        </span>

        <span className='close-note-4'>
        <img
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            src={note4} 
            className={NoteclassArr[3]}
            id='3'  
          ></img>
        <img src={openNote4} className="note-open open-note-4"></img>
        </span>

        <span className='close-note-5'>
        <img
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            src={note5} 
            className={NoteclassArr[4]}
            id='4'  
          ></img>
        <img src={openNote5} className="note-open open-note-5"></img>
        </span>

        <span className='close-note-6'>
        <img
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            src={note6} 
            className={NoteclassArr[5]}
            id='5'  
          ></img>
        <img src={openNote6} className="note-open open-note-6"></img>
        </span>

        <span className='close-note-7'>
        <img
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            src={note7} 
            className={NoteclassArr[6]}
            id='6'  
          ></img>
        <img src={openNote7} className="note-open open-note-7"></img>
        </span>

        <span className='close-note-8'>
        <img
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            src={note8} 
            className={NoteclassArr[7]}
            id='7'  
          ></img>
        <img src={openNote8} className="note-open open-note-8"></img>
        </span>

        <span className='close-note-9'>
        <img
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            src={note9} 
            className={NoteclassArr[8]}
            id='8'  
          ></img>
        <img src={openNote9} className="note-open open-note-9"></img>
        </span>

    </>
  )
}
