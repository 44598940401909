import React from 'react';
import "../Styles/AimeSolution.scss"

import aimeSolutionHeader from "../matirials/Headers/header-solusion.svg"
import UI_SolutionValues from './UI_SolutionValues';

import aimeCharectur from "../matirials/Aime/aime-charectur.png"

export default function AimeSolution(props) {
  return (
    <div className='aime-solution-main-container' id={props.id}>
        <img className='aime-solution-header' src={aimeSolutionHeader}></img>

        <div className='aime-solution-grid'>
            <div className='aime-solution-p-flex'>
              <p>The aim is to make an <b>easy-to-use platform for creating personalized working plans</b> that fit the needs of small business owners.<b> This includes pre-ordered plans for areas like marketing and increasing income. </b>The app is designed to provide clear information and knowledge for those who may lack clarity and understanding in these areas.<b> It's like a first “pit stop” to help them gain the knowledge they need.</b> I used the following values to guide me throughout the process:</p>
              <UI_SolutionValues 
                val1="Gets results"
                val2="Fun"
                val3="Simple"
              />
            </div>
            <img src={aimeCharectur}></img>
        </div>
    </div>
  )
}
