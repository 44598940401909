import React from 'react';
import { Link } from "react-router-dom"
import UI_NextProgect from './UI_NextProgect';

import NextImg from "../matirials/Next-project/next-aime.png";
import NextLogo from "../matirials/Home/aime-logo-row.svg"
import { Fade } from "react-awesome-reveal";

export default function RunGoNext(props) {
  return (
    <Fade delay={100}>
    <Link to="/aime" style={{textDecoration: "none"}}>
    <div className='next-container' id={props.id}> 
    <UI_NextProgect
        nextImg = {<img src={NextImg} className="next-img"></img>}
        nextLogo = {<img src={NextLogo} className="next-logo"></img>}
    />
    </div>
    </Link>
    </Fade>
  )
}
