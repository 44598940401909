import React from 'react';
import "./UI_ColorSwatch.scss"; 
import { Fade } from "react-awesome-reveal";


export default function UI_ColorSwatch(props) {

    const bgColor = {
        background: props.color
    }

 
  return (
    <>
<Fade  delay={100}>
    <div className='color-swatch-flex'>

        <div 
            className='color-swatch-squere' style={bgColor}> 
            <div 
             className='color-tool-tip'>
                {props.text}
                {props.img} 
            </div>
        </div>
        
        <h1 className='color-swatch-name'>{props.color}</h1>
    </div>
    </Fade>
    </>
  )
}
