import React from 'react';
import "../Styles/AimeFlow.scss";

import aimeFlowHeader from "../matirials/Headers/header-flow.svg";
import aimeFlow from "../matirials/Aime/aime-flow.png"

export default function AimeFlow(props) {
  return (
    <div className='aime-flow-container' id={props.id}>
        <img src={aimeFlowHeader} className="aime-flow-header"></img>
        <div className="aime-flow">
            <img src={aimeFlow}></img>
        </div>
    </div>
  )
}
