import UI_ProjectPageHero from '../Components/UI_ProjectPageHero';
import UI_ProjectVision from '../Components/UI_ProjectVision';
import AimeUserIntervies from '../Components/AimeUserIntervies';

import aimeLogoHero from "../matirials/Aime/aime-hero-logo.svg";
import aimeImgHero from "../matirials/Aime/aime-hero-img.png";
import UI_NoteLayerAime from '../Components/UI_NoteLayerAime';
import AimeCharts from '../Components/AimeCharts';
import AimePersonas from '../Components/AimePersonas';
import AimeSolution from '../Components/AimeSolution';
import AimeFlow from '../Components/AimeFlow';
import AimeColors from '../Components/AimeColors';
import UIMobile  from '../Components/UI_Mobile';
import AimeGoNext  from '../Components/AimeGoNext';

import aimeUImamge1 from '../matirials/Aime/aime-ui-mockup-1.png';
import aimeUImamge2 from '../matirials/Aime/aime-ui-mockup-2.png';
import aimeUImamge3 from '../matirials/Aime/aime-ui-mockup-3.png';
import aimeUImamge4 from '../matirials/Aime/aime-ui-mockup-4.png';
import aimeUImamge5 from '../matirials/Aime/aime-ui-mockup-5.png';
import AimeScroller from '../Scroller/AimeScroller';
import UI_BackHomePage from '../Components/UI_BackHomePage';

import UI_Footer from "../Components/UI_Footer"
import AimeNav from '../Components/AimeNav';
import AimeCopm from '../Components/AimeCopm';


export default function Aime() {


  return (
    <div >

    <AimeScroller />

    <UI_BackHomePage />

    <UI_ProjectPageHero id="aimeHero"
      logo = {<img src={aimeLogoHero} alt=""></img>}
      description = {<h1>UX | UI | Mobile App</h1>}
      img = {<img className='hero-img-project' src={aimeImgHero} alt=""></img>}    
    />

    <UI_ProjectVision id="aimeVision" visionText={<h1>The aim is to assist small business owners in achieving their goals by creating work plans in a fun and hands-on manner.</h1>}/>

    <AimeUserIntervies id="AimeUserIntervies" />

    <AimePersonas id="AimePersonas"/>

    <AimeCharts id="AimeCharts"/>

    <UI_NoteLayerAime id="AimeNotes"/>

    <AimeSolution id="AimeSolution"/>

    <AimeFlow id="AimeFlow"/> 

    <AimeNav id="aimeNav"/>

    <AimeCopm id="aimeCopm" />

    <AimeColors id="AimeColors"/>

    <UIMobile  id="AimeUIMobile"
      img1={<img src={aimeUImamge1} className="run-ui-img-item"></img>}
      img2={<img src={aimeUImamge2} className="run-ui-img-item"></img>}
      img3={<img src={aimeUImamge3} className="run-ui-img-item"></img>}
      img4={<img src={aimeUImamge4} className="run-ui-img-item"></img>}
      img5={<img src={aimeUImamge5} className="run-ui-img-item"></img>}
    />   

    <AimeGoNext id="AimeGoNext"/>

    <UI_Footer />
    
    </div>
  )
}
