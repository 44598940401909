import React from 'react';
import "../Styles/DashboardColors.scss";

import dashColorsHeader from "../matirials/Headers/header-colors.svg"
import UI_ColorSwatch from './UI_ColorSwatch';

import hoverIcon from "../matirials/Aime/hover-icon.svg"


//import tool tips img 
import toolImgPrimaryBlue from "../matirials/Dashboard/dash-color-tool-tip-primaryblue.png"
import toolImgSeconderyBlue from "../matirials/Dashboard/dash-color-tool-tip-seconderyblue.png"

import toolImgdarkgrey from "../matirials/Dashboard/dash-color-tool-tip-darkgrey.png"
import toolImgmediumgrey from "../matirials/Dashboard/dash-color-tool-tip-mediumgrey.png"
import toolImglightgrey from "../matirials/Dashboard/dash-color-tool-tip-lightgrey.png"

import toolImglightgreen from "../matirials/Dashboard/dash-color-tool-tip-lightgreen.png"
import toolImgmediumgreen from "../matirials/Dashboard/dash-color-tool-tip-mediumgreen.png"
import toolImgdarkgreen from "../matirials/Dashboard/dash-color-tool-tip-darkgreen.png"

import toolImglightyellow from "../matirials/Dashboard/dash-color-tool-tip-lightyellow.png"
import toolImgdatkYellow from "../matirials/Dashboard/dash-color-tool-tip-darkyellow.png"

import toolImglightred from "../matirials/Dashboard/dash-color-tool-tip-lightred.png"
import toolImgmediumred from "../matirials/Dashboard/dash-color-tool-tip-mediumred.png"
import toolImgdarkred from "../matirials/Dashboard/dash-color-tool-tip-darkred.png"


export default function DashboardColors(props) {
  return (
    <div className='dash-colors-container' id={props.id}>
        <img src={dashColorsHeader} className="dash-colors-header"></img>

        <div className='hover-flex-container-in-dash-colors'>
                  <img className="hover-icon"src={hoverIcon}></img> 
                  <h1 className='hover-h1'>hover swaches to reveal their secrets</h1>
                </div>

    <div className='dash-align-colors'>
    <div className="dash-color-top">
            <div className='dash-colors-group'>
            <h1 className='dash-color-gropu-h1'>Primary</h1>
            <div className='dash-colors-group-flex'>
                <UI_ColorSwatch 
                    color='#007AFF' 
                    text={<p><b>For a primary color,</b> I chose a classic blue, to give an experience of stability & feeling of "something familiar to the eye". <b>Used on primary CTA.</b></p>}
                    img={<img src={toolImgPrimaryBlue} className="color-tooltip-img"></img>}
                />
                <UI_ColorSwatch 
                    color='#D9EBFF'
                    text={<p><b>For secondery color,</b> I have taken the primary blue, and lower it opacity to 15%. <b>used on secondery CTA.</b></p>}
                    img={<img src={toolImgSeconderyBlue} className="color-tooltip-img"></img>}
                />
            </div>
        </div>

            <div className='dash-colors-group'>
            <h1 className='dash-color-gropu-h1'>Neutral</h1>
            <div className='dash-colors-group-flex'>
                <UI_ColorSwatch 
                    color='#0A1B33'
                    text={<p>My nutral colors are very dark shade of the primary blue. <b>I've used this shade on headers & main navigation.</b> </p>}
                    img={<img src={toolImgdarkgrey} className="color-tooltip-img"></img>}
                />
                <UI_ColorSwatch
                    text={<p>My nutral colors are very dark shade of the primary blue. <b>I've used this shade for paragraphs secondery navigation & chosen tabs in navigation.</b></p>}
                    img={<img src={toolImgmediumgrey} className="color-tooltip-img"></img>} 
                    color='#293951'
                />
                <UI_ColorSwatch 
                color='#F5F6FA'
                text={<p>I've used a very lifght "blue-grey" shade for the  background, to make the content of the app pop.</p>}
                img={<img src={toolImglightgrey} className="color-tooltip-img"></img>}
                />
            </div>
        </div>
     </div>   

     <div className="dash-color-bottom">
            
            <div className='dash-colors-group'>
            <h1 className='dash-color-gropu-h1'>Positive State</h1>     
            <div className='dash-colors-group-flex'>
                <UI_ColorSwatch 
                color='#E5F7EC'
                text={<p>I've used green shade for the positive state in the app. <b>This shade used as "secondery-green" to support the primary shade.</b></p>}
                img={<img src={toolImglightgreen} className="color-tooltip-img"></img>}
                />
                <UI_ColorSwatch 
                color='#52CC83'
                text={<p>I've used green shade for the positive state in the app. <b>This shade used as primary shade for that states.</b></p>}
                img={<img src={toolImgmediumgreen} className="color-tooltip-img"></img>}
                />
                <UI_ColorSwatch 
                color='#248A3D'
                text={<p>I've used green shade for the positive state in the app. <b>This shade used as ״accessibility shade" for the text.</b></p>}
                img={<img src={toolImgdarkgreen} className="color-tooltip-img"></img>}
                />
            </div> 
            </div>
        
            <div className='dash-colors-group'>
            <h1 className='dash-color-gropu-h1'>Warning State</h1>  
            <div className='dash-colors-group-flex'>
                <UI_ColorSwatch 
                color='#FFF8D9'
                text={<p>I've used yellow shade for the warrning state in the app. <b>This shade used as "secondery-yellow" to support the primary shade.</b></p>}
                img={<img src={toolImglightyellow} className="color-tooltip-img"></img>}
                />
                <UI_ColorSwatch 
                color='#FFCC00'
                text={<p>I've used yellow shade for the warrning state in the app. <b>Due to accessibility, I did not use this shade, but used it as inspiration for the other shades.</b></p>}
                img={<div className="color-tooltip-img"></div>} 
                />
                <UI_ColorSwatch 
                color='#EDBA00'
                text={<p>I've used yellow shade for the warrning state in the app. <b>This shade used as ״accessibility shade" for the text.</b></p>}
                img={<img src={toolImgdatkYellow} className="color-tooltip-img"></img>}
                />
            </div>
            </div>
        
            <div className='dash-colors-group'>
            <h1 className='dash-color-gropu-h1'>Negative State</h1>  
            <div className='dash-colors-group-flex'>
                <UI_ColorSwatch 
                color='#FFE2E0'
                text={<p>I've used red shade for the negative state in the app. <b>This shade used as "secondery-red" to support the primary shade.</b></p>}
                img={<img src={toolImglightred} className="color-tooltip-img"></img>}
                />
                <UI_ColorSwatch 
                color='#FF3B30'
                text={<p>I've used red shade for the negative state in the app. <b>This shade used as primary shade for that states.</b></p>}
                img={<img src={toolImgmediumred} className="color-tooltip-img"></img>}
                />
                <UI_ColorSwatch 
                color='#D70015'
                text={<p>I've used red shade for the negative state in the app. <b>This shade used as ״accessibility shade" for the text.</b></p>}
                img={<img src={toolImgdarkred} className="color-tooltip-img"></img>}
                />
            </div>
            </div>

         </div>
         </div>
    </div>
  )
}
