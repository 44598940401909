import React from 'react';
import UI_ProjectPageHero from '../Components/UI_ProjectPageHero';

import runLogoHero from "../matirials/Run/run-hero-logo.svg";
import runImgHero from "../matirials/Run/run-hero-img.png";
import UI_ProjectVision from '../Components/UI_ProjectVision';
import RunUserIntervies from '../Components/RunUserIntervies';
import UI_NoteLayer from '../Components/UI_NoteLayer';
import RunSolution from '../Components/RunSolution';
import RunFlow from '../Components/RunFlow';
import RunColors from '../Components/RunColors';
import RunTypo from '../Components/RunTypo';
import RunPhoto from '../Components/RunPhoto';
import UIMobile  from '../Components/UI_Mobile';


import runUImamge1 from "../matirials/Run/run-ui-1.png";
import runUImamge2 from "../matirials/Run/run-ui-2.png";
import runUImamge3 from "../matirials/Run/run-ui-3.png";
import runUImamge4 from "../matirials/Run/run-ui-4.png";
import runUImamge5 from "../matirials/Run/run-ui-5.png";
import RunGoNext from '../Components/RunGoNext';
import RunScroller from '../Scroller/RunScroller';
import UI_BackHomePage from "../Components/UI_BackHomePage.js";
import UI_Footer from "../Components/UI_Footer"


export default function Run() {
  return (
    <>
    <RunScroller />

    <UI_BackHomePage />

    <UI_ProjectPageHero id="RunHero"
      logo = {<img src={runLogoHero} alt=""></img>}
      description = {<h1>UX | UI | Mobile App</h1>}
      img = {<img className='hero-img-project' src={runImgHero} alt=""></img>}    
    />

    <UI_ProjectVision id="RunVision" visionText={<h1>The goal is to enhance running groups' experience by providing a single platform to manage both the group and running activities.</h1>}/>
    
    <RunUserIntervies id="RunUserIntervies"/>

    <UI_NoteLayer id="RunNotes"/>

    <RunSolution id="RunSolution" />

    <RunFlow id="RunFlow" />

    <RunColors id="RunColors"/>

    <RunTypo id="RunTypo"/>

    <RunPhoto  id="RunPhoto" /> 

    <UIMobile  id="RunUI"
      img1={<img src={runUImamge1} className="run-ui-img-item"></img>}
      img2={<img src={runUImamge2} className="run-ui-img-item"></img>}
      img3={<img src={runUImamge3} className="run-ui-img-item"></img>}
      img4={<img src={runUImamge4} className="run-ui-img-item"></img>}
      img5={<img src={runUImamge5} className="run-ui-img-item"></img>}
    />   

    <RunGoNext id="RunGoNext"/>

    <UI_Footer />  
    </>

  )
}
