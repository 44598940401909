import React from 'react';
import "../Styles/DashbordWireFrames.scss";

import headerWireDash from "../matirials/Headers/header-wire.svg";
import DashWIreGallery from './DashWIreGallery';

import hoverIcon from "../matirials/Aime/hover-icon.svg"


export default function DashbordWireFrames(props) {
  return (
    <div className='dash-wire-container' id={props.id}>
        <img src={headerWireDash} alt="" className='dash-wire-header'></img>

        <div className='wireframes-dash-flex-container'>

            <div className='gallery-and-hover-h1'>

                <div className='hover-flex-container'>
                  <img className="hover-icon"src={hoverIcon}></img> 
                  <h1 className='hover-h1'>hover for some fun</h1>
                </div>

                <DashWIreGallery />
            </div>

        </div>

    </div>
  )
}
