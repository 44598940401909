import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./UI_BackHomePage.scss"; 
import BackHomeIcon from "../matirials/Next-project/back-home-icon.svg";

export default function UI_BackHomePage() {

    const [isVisible, setIsVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
  
    useEffect(() => {
      const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollPos > currentScrollPos || currentScrollPos === 0;
  
        setIsVisible(visible);
        setPrevScrollPos(currentScrollPos);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

  return (
    <Link to="/" style={{textDecoration: "none"}}>
      <div className={ isVisible ? 'back-home-flex' : 'back-home-flex none-display'}>
        <img src={BackHomeIcon}></img>
        <h1>Home</h1>
      </div>
    </Link>
  )
}
