// בשביל לשכפל לקומפוננטה בפרוייקט המקביל, בעתק הדבק ולששנות את הטקסטים

import React from 'react'
import UI_UserIntervies from './UI_UserIntervies';

import interviewMainHeader from "../matirials/Headers/header-intervies.svg"

import motivationIcon from "../matirials/Aime/check-mark.svg";
import frustrationIcon from "../matirials/Aime/x-mark.svg";

import qouteStart from "../matirials/Aime/qoute-start.svg";
import qouteEnd from "../matirials/Aime/qoute-end.svg";


export default function AimeUserIntervies(props) {
  return (
    <div className='intervies-section-container' id={props.id}>
        <img src={interviewMainHeader} className='interview-main-header'></img>

        <div className='intervies-content-grid'>
        <div className='motivation-container'>

            <h1 className='motavationHeader'>Motivations</h1>

            <UI_UserIntervies 
                text= {<p>I want to know from where to begin.</p>}
                icon={<img src={motivationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I want to get results.</p>}
                icon={<img src={motivationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I want to confirm whether my business is heading in the right direction.</p>}
                icon={<img src={motivationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I want to learn new skills to make my business better.</p>}
                icon={<img src={motivationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I want to organize things in my business.</p>}
                icon={<img src={motivationIcon} alt=""></img>}
            />

            <div className='motivation-qute-container'>
                <img src={qouteStart} className='qute-start-sign'></img>
                <p className='qute-text'>I want to have a clear system in place so that I know what I'm doing, when I'm doing it, and how to do it</p>
                <img src={qouteEnd} className='qute-end-sign'></img>
            </div>
        </div>

        <div className='frustration-container'>

            <h1 className='frustrationHeader'>Frustrations</h1>

            <UI_UserIntervies 
                text= {<p>I don't want it to be complicated.</p>}
                icon={<img src={frustrationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I don't want to feel restricted or lose my freedom.</p>}
                icon={<img src={frustrationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I don’t want it to be a waist of money.</p>}
                icon={<img src={frustrationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I don't want to feel let down by myself.</p>}
                icon={<img src={frustrationIcon} alt=""></img>}
            />

            <UI_UserIntervies 
                text= {<p>I don't want to feel overwhelmed by the work plans.</p>}
                icon={<img src={frustrationIcon} alt=""></img>}
            />

            <div className='frustration-qute-container'>
                <img src={qouteStart} className='qute-start-sign'></img>
                <p className='qute-text'>My business is declining, and I believe that if I improve my organization, I'll have more spare time</p>
                <img src={qouteEnd} className='qute-end-sign'></img>
            </div>

        </div>
        </div>
    </div>
  )
}