import React from 'react'; 
import { Link } from "react-router-dom"
import "../Styles/Error.scss";

import errorItem from "../matirials/Error/error-img.png";
import errorHeader from "../matirials/Headers/header-error.svg";

export default function Error() {
  return (
    <div className='error-container'>
      <img className='error-header' src={errorHeader}></img>
      <img className='error-img' src={errorItem}></img>
      <p className='not-found'>Page Not Found</p>
      <Link to="/" style={{textDecoration: "none"}}>
      <button>Home Page</button>
      </Link>
      <p className='p-bottom'>Designed & Developed by Roni Mor in React</p>
    </div>
  )
}
