import React from 'react';
import ScrollItem from './ScrollItem';

export default function RunScroller() {
  return (
    <nav>
    <ul>
        <ScrollItem id="RunHero" label="Project"/>
        <ScrollItem id="RunVision" label="Vision"/>
        <ScrollItem id="RunUserIntervies" label="User Interviews"/>
        <ScrollItem id="RunNotes" label="Lean UX Canvas"/>
        <ScrollItem id="RunSolution" label="Solution"/>
        <ScrollItem id="RunFlow" label="Flow"/>
        <ScrollItem id="RunColors" label="Colors"/>
        <ScrollItem id="RunTypo" label="Typography"/>
        <ScrollItem id="RunPhoto" label="Photographic"/>
        <ScrollItem id="RunUI" label="UI"/>
        <ScrollItem id="RunGoNext" label="Next Project"/>
    </ul>
</nav>
  )
}
