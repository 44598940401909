import React from 'react';
import "./UI_Footer.scss";

import mailIcon from "../matirials/Footer/icon-mail.svg"
import phoneIcon from "../matirials/Footer/icon-phone.svg"


export default function UI_Footer(props) {
  return (
    <footer id={props.id} className="footer-container">
        <h1>Let's Work Together!</h1>
        <div className='contact-fotter-flex'>
        <div className='mail-container'>
            <img src={mailIcon}></img>
            <h2>1ronimor@gmail.com</h2>
        </div>

        <div className='phone-container'>
            <img src={phoneIcon}></img>
            <h2>052-349-5051</h2>
        </div>
        </div>

        <p>Created & Developed by Roni Mor with React</p>
    </footer>
  )
}
