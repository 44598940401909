import React from 'react';
import "../Styles/DashboardDate.scss";
import DataArchitecture from "../matirials/Headers/header-architecture.svg";

export default function DashboardData(props) {
  return (
    <div className='Data-container' id={props.id}>
        <img src={DataArchitecture} alt="" className='data-architecture'></img>
        {/* <img src={} alt=""  className='the-cover-img'></img> */}
    </div>
  )
}
