import React from 'react';
import ScrollItem from './ScrollItem';

export default function DashboardScroller() {
  return (
    <nav>
        <ul>
            <ScrollItem id="DashbordProjectHero" label="Project"/>
            <ScrollItem id="DashbordProjectVision" label="Vision"/>
            <ScrollItem id="DashboardData" label="Data Architecture"/>
            <ScrollItem id="DashbordSolution" label="Solution"/>
            <ScrollItem id="DashbordWireFrames" label="Wire Frames"/>
            <ScrollItem id="DashboardColors" label="Colors"/>
            <ScrollItem id="DashboardTypography" label="Typography"/>
            <ScrollItem id="DashboardUI" label="UI"/>
            <ScrollItem id="DashboardGoNext" label="Next Project"/>
        </ul>
    </nav>
  )
}
