import React from 'react';
import "../Styles/DashboardTypography.scss";

import DashTypograpyHeader from "../matirials/Headers/header-typo.svg";
import dashTypographyImg from "../matirials/Dashboard/dash-typography.png"

import Tilt from "react-parallax-tilt";

export default function DashboardTypography(props) {
  return (
    <>
    <div className='dash-typograpy-main-container' id={props.id}>

        <img src={DashTypograpyHeader} className="dash-typography-main-header"></img>

        <div className='dash-typography-grid'>
            <Tilt className='dash-typography-tilt'
                  tiltMaxAngleX="10" 
                  tiltMaxAngleY="10"
                  transitionSpeed={1500}
                  >
                    
                <img src={dashTypographyImg} className="dash-typography-img"></img>
            </Tilt>
            <p className="dash-typography-p">My main use of typography was to create a <b>hierarchy for the user, to help him understand what is more important than what,</b> I chose a simple font, not too "attractive to the eye" to create an "seamless" design to <b>emphasize the essence of the system - the information it provides.</b></p>
        </div>

    </div>
    </>
  )
}
